import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import { darken } from 'polished'
import { Layout, Wrapper, SectionTitle } from '../components'
import SubPageHeader from '../components/SubPageHeader'
import Note from '../components/Note'
import config from '../../config'
import Logo from '../assets/img/logo.svg'

const Content = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem 4rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  margin-top: -3rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }

  .lead {
    font-size: 1.43rem;
    line-height: 1.5;
  }

  .text-center {
    margin: 2rem auto;
    text-align: center;
  }

  cite {
    width: 100%;
    display: block;
    margin-top: -2.5rem;
    margin-bottom: 3rem;
    text-align: right;
    font-size: 0.9rem;
    font-style: normal;
  }
`

const Title = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;

  div {
    padding: 2rem 1rem 0;
    text-align: center;

    h5, h3 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`

const Intro = styled.div`
  padding: 1rem 5rem;

  &.post-quote {
    margin-top: -2rem;
  }
`

const SectionOne = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 250px;
    height: 250px;
    margin: 1rem;
    border: 5px solid whitesmoke;
    border-radius: 500px;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);
  }

  @media (min-width: ${props => props.theme.breakpoints.phone}) {
    flex-direction: row;
    justify-content: space-between;

    img {
      float: right;
    }
  }
`

const Block = styled.div`
  margin-top: 5rem;
  padding: 3rem 0 5rem 0;
  background: url('/img/asanoha.png') left top repeat;
  border-top: 1px solid ${darken(0.1, '#F5F5F5')};
  border-bottom: 1px solid ${darken(0.1, '#F5F5F5')};
`

const About = () => (
  <Layout>
    <Wrapper>
      <Helmet title={`About | ${config.siteTitle}`} />
      <SubPageHeader />
      <Content>
        <Title>
          <div>
            <h3>Self-Development Lab</h3>
            <h5>Tricia Andor</h5>
          </div>
        </Title>
        <Intro>
          <p className="lead">Does your people-pleasing make you feel like you can't be who you want to be? Saying "yes" to doing things you don't actually want to do? Trying so hard to be nice to everyone that you aren't yourself? Having low self-esteem &mdash; the ever-present twin to people-pleasing?</p>
        </Intro>
        <SectionOne>
          <div>
            <p>Hi there, I’m Tricia.</p>
            <p>I'm a Licensed Professional Counselor and self-development coach. I work with all kinds of people, but lately I've been helping a lot of smart, caring people-pleasers learn to say "no" without feeling guilty, and to remain calm when they have to interact with difficult people.</p>
            <p>With a master's degree in clinical psychology and twenty years experience as a therapist, I teach proven, psychology-based skills to my clients so they can reduce workplace drama, handle stress in ways that gain respect, and confidently deal with hurt feelings and annoyances.</p>
          </div>
          <img src="/img/tricia-andor.png" alt="Tricia Andor" />
        </SectionOne>
        <p><strong>People come to me because they want to:</strong></p>
        <ul>
          <li>Draw lines and say "no" to coworkers, employees, and supervisors when necessary.</li>
          <li>Remain calm instead of feeling anxious when dealing with uncooperative people at work.</li>
          <li>Have more self-confidence.</li>
        </ul>
        <p><strong>And because they want to be done:</strong></p>
        <ul>
          <li>Stressing out from trying to make everyone like them.</li>
          <li>Giving their time and energy to draining relationships with controlling, needy, unhappy people.</li>
          <li>Doing things out of fear, obligation and guilt.</li>
        </ul>
        <p className="lead text-center">Maybe you can relate.</p>
        <p>You see other people not getting sucked into workplace drama the way you do and wonder, <em>why can't that be me?</em></p>
        <p>You hear others saying "no" without going into a downward spiral of guilt. <em>I wish I could do that.</em></p>
        <p>You see other supervisors tell their direct reports what to do without tiptoe-ing or second-guessing themselves. <em>How do they do that?</em></p>
        <p>You clean up people’s messes a lot and wonder, <em>why am I always the one picking up the pieces?</em></p>
        <Intro>
          <p className="lead">And this makes total sense. Because when you’re on the outside looking in, it can feel like the best thing you can do for yourself is to keep observing other people doing the things you wish you could do. </p>
        </Intro>
        <p>But the problem is, that leaves you in the same place &mdash; your own life still isn't what what you want it to be.</p>
          <p><strong>But what if it doesn't have to be that way?</strong></p>
        <p>From what I've seen, it doesn't.</p>
        <p>Hundreds of people have upgraded their self-esteem, cultivated calmness, and mastered communication skills they didn't know they were capable of as a result of working with me.</p>
        <p>My clients get the outcomes they’re seeking because they learn proven, time-tested skills they can use for a lifetime. What I teach is different from this month’s “golden nugget” you’ll see circulating the internet (usually from a psychology book that recently hit the bestseller list).</p>
        <p>When you’re a client of mine, you can rest assured that you’re not going to get generic, what’s-hot-for-the-moment tactics. Instead, you get hand-picked skills, scripts, and mindsets that pair up as the best solution for your unique challenge.</p>
        <Intro>
          <p className="lead">You’ve probably heard people like Oprah, author Donald Miller, or preacher Joyce Meyer talk about how they <em>used</em> to be a people-pleaser, but <em>aren't anymore...</em></p>
        </Intro>
        <p><em>Used to be</em> a people-pleaser? Past-tense? Can that really happen?</p>
        <p>When you’ve got the right tools + the grit to try new things &mdash; YES!</p>
          <p>Maybe you’ve dared to dream that big things could happen in your life also, and a part of you <em>knows</em> it's possible. You know that with a little help you could experience a change like my client, Maria:</p>
        <blockquote>
          <p>I feel much better about myself. My self-esteem is better. Now I don't let things go and hope that bad situations turn out okay on their own in the hopes that people like me. I’ve learned how to assert myself without being contentious, or angry, or argumentative. I’ve learned to not hang onto other people’s negative feelings and make them my problem.</p>
        </blockquote>
        <cite>&mdash; Maria, Nurse</cite>
        <Intro className="post-quote">
          <p><span className="lead">You were meant for more than living in the shadow of other people’s personalities.</span></p>
        </Intro>
        <p> Of your fears. Of doing other people’s work and not being appreciated for it. Of running on empty because you agreed to do things you wish you could have said "no" to. Of avoiding conflict and difficult people at all costs.</p>
        <p>My hope is that it’s time for you to move forward, out of people-pleasing and into that confident, vibrant, healthy-self-esteem person you want to be. If so, I’d love to help you in your journey ahead.</p>
        <p>Take the first step by signing up for my free ebook <a href="/"><em>How to Say “No” Without Feeling Like a Selfish Jerk</em></a>. In it, I share a personal story about how I said "no" in an uncomfortable situation, and give you guidelines on how you can too. You’ll also get access to my articles that show you how to create better relationships, self-esteem, and acceptance in your life.</p>
        <h4>Tricia's Bio</h4>
        <p>Tricia is a therapist and  self-development coach who helps smart, caring people-pleasers develop guilt-free self-confidence, learn to say "no," and get the time and energy they deserve.</p>
        <p>With a master's degree in clinical psychology and twenty years experience as a Licensed Professional Counselor, Tricia uses the best psychology-based tools to help her clients stay focused, be kinder to themselves, and dream bigger.</p>
          <p>Tricia has been featured in Woman's Day, Cosmopolitan, and GirlFriendCircles. She offers one-on-one coaching and group programs. You can get her free ebook <a href="/">here</a>.</p>
        <Note note="Self Development Lab is an educational and coaching website. While I am a therapist, I am not **your** therapist. All information on this website and its courses is for general informational purposes only and is not therapy advice. Content offered either on this website or Self Development Lab courses does not create a therapist-client relationship. The author is not liable for any losses or damages related to actions or failure to act related to the content on this website. If you need a therapist, consult with a licensed therapist in your state of residence who specializes in the topic for which you seek help."></Note>
      </Content>
    </Wrapper>
  </Layout>
)

export default About

